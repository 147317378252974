<nav #navbar class="navbar active">
  <div class="container-fluid" style="background-image: linear-gradient(to bottom, green, rgb(8, 71, 8));">
    <div class="navbar-header" style="background-image: linear-gradient(to bottom, green, rgb(8, 71, 8));">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img class="center" src="assets/images/logo.png" style="height: 60px;margin-top: -13px;" alt="" />
        <!-- <span class="logo-name">Cliniva</span> -->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <!-- <div class="row" ngbDropdown style="margin: 0px;list-style-type: none;">
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="text-align: center;">
          <mat-icon style="color: rgb(255, 217, 0);">settings</mat-icon>
          <p style="color: rgb(255, 217, 0);">Settings</p>
        </div>
      </div> -->
      <ul class="nav navbar-nav navbar-right" style="line-height: 0px !important;">
        <!-- Full Screen Button -->
        <!-- <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li> -->
        <!-- #END# Full Screen Button -->
        <!-- <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <li class="nav-item hvr" (click)="dashboardBtn()" ngbDropdown style="text-align: center;padding:5px;color: whitesmoke;">
          <mat-icon style="padding-top: 5px;">dashboard</mat-icon>
          <p style="padding-top: 15px;">Dashboard</p>
        </li>
        <li *ngIf="usertype!='Super Administrator'" (click)="profileBtn()" class="nav-item hvr" ngbDropdown style="text-align: center;padding:5px;color: rgb(255, 217, 0);">
          <mat-icon style="padding-top: 5px;">person</mat-icon>
          <p style="padding-top: 15px;">My Profile</p>
        </li>
        <li class="nav-item hvr" ngbDropdown style="text-align: center;padding:5px;color: whitesmoke;">
          <mat-icon style="padding-top: 5px;">settings</mat-icon>
          <p style="padding-top: 15px;">Settings</p>
        </li>
        <li class="nav-item hvr" (click)="userBtn()" ngbDropdown style="text-align: center;padding:5px;color: white;">
          <mat-icon style="padding-top: 5px;">group</mat-icon>
          <p style="padding-top: 15px;">User Access</p>
        </li>
        <li class="nav-item hvr" (click)="approvalBtn()" ngbDropdown style="text-align: center;padding:5px;color: white;">
          <mat-icon style="padding-top: 5px;">verified</mat-icon>
          <p style="padding-top: 15px;">Approvals</p>
        </li>
        <li class="nav-item hvr" (click)="gameBtn()" ngbDropdown style="text-align: center;padding:5px;color: white;">
          <mat-icon style="padding-top: 5px;">sports_esports</mat-icon>
          <p style="padding-top: 15px;">Games</p>
        </li>
        <li class="nav-item hvr" ngbDropdown style="text-align: center;padding:5px;color: white;">
          <mat-icon style="padding-top: 5px;">description</mat-icon>
          <p style="padding-top: 15px;">Reports</p>
        </li>
        <li *ngIf="usertype=='Super Administrator'" (click)="newsBtn()" class="nav-item hvr" ngbDropdown style="text-align: center;padding:5px;color: whitesmoke;">
          <mat-icon style="padding-top: 5px;">newspaper</mat-icon>
          <p style="padding-top: 15px;">News & Events</p>
        </li>
        <li class="nav-item hvr" (click)="logout()" ngbDropdown style="text-align: center;padding:5px;color: white;">
          <mat-icon style="padding-top: 5px;">logout</mat-icon>
          <p style="padding-top: 15px;">Logout</p>
        </li>
        <!-- #START# Notifications-->

        <!-- <li class="nav-item" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon style="color: rgb(255, 217, 0);margin-top: 15px;">notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1">Read
              All Notifications</a>
          </div>
        </li> -->
        <!-- #END# Notifications-->
        <!-- <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img *ngIf="userImg!=null" src={{userImg}} class="rounded-circle" width="32" height="32" alt="User">
            <img *ngIf="userImg==null" style="margin-right: 10px;" src="../../../assets/images/default-user.png" class="rounded-circle" width="32" height="32" alt="User">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a (click)="onEdit()" onClick="return false;" style="cursor: pointer;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li> -->
        <!-- #END# Tasks -->
        <li class="pull-right">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <!-- <mat-icon id="settingBtn">settings</mat-icon> -->
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
